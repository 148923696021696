import React from "react";
// import { Link } from "react-router-dom";

const AboutAreaSection = () => {
  return (
    <>
      <section className="space">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="image-box1">
                <img
                  className="img1"
                  src="assets/img/about/lucky-tour-about-Img.jpg"
                  alt="image1"
                />
                <img
                  className="img2"
                  src="assets/img/about/img-2-2.jpg"
                  alt="image2"
                />
                <div className="media-box1">
                  <span className="media-info">25 Years</span>
                  <p className="media-text">Of Experience</p>
                </div>
                <div className="media-box2">
                  <span className="media-info">20,000+</span>
                  <p className="media-text">Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-content">
                <div className="title-area">
                  <span className="sec-subtitle">
                    We are to Lucky Tour and Travel
                  </span>
                  <h2 className="sec-title h1">
                    We Are The Best For Your Travel
                  </h2>
                  <p className="sec-text" style={{textAlign : "justify"}}>
                    Lucky Tour and Travel is One of The Reputed and Leading
                    Tourism Company in Thailand. Planning an extended vacation
                    or simply a weekend getaway? We Lucky Tour and Travel tend
                    to believe that your own personal trip needs you to own your
                    own personal tour plan. We all know that the plans will be
                    amended as you go, which your tour ought to be simply
                    adjustable so as to assist you to modify your plans once
                    that happens.
                    <br />
                    <br />
                    We Believe That Coming up with the Small Print of Your Trip
                    May be as Gratifying Because of the Trip Itself.
                    <br />
                    <br />
                    Lucky Tour and Travel provides Tour package, visiting the
                    city is all regarding the fun of coming up with the main
                    points of your trip. Lucky Tour and Travel tends to believe
                    that your own personal trip needs you to own your own tour
                    plan.
                  </p>
                </div>
                {/* <ul className="about-list1">
                  <li>Hotel Booking</li>
                  <li>Air Ticket</li>
                  <li>Tour Packages</li>
                  <li>Transportation</li>
                  <li>Medical Appointment</li>
                  <li>Money Exchange</li>
                  <li>Tourist SIM Card</li>
                  <li>B2B (Thailand)</li>
                </ul>
                <Link to="/about" className="vs-btn style4">
                  View More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAreaSection;
