import React from "react";
import AboutHeroSection from "./AboutHeroSection";
import AboutAreaSection from "./AboutAreaSection";

// import AboutSpecialOffer from "./AboutSpecialOffer";
// import TestimonialSlider from "./TestimonialSlider";
import MissionAreaSecton from "./MissionAreaSecton";
import VisionAreaSection from "./VisionAreaSection";

const About = () => {
  return (
    <>
      <AboutHeroSection />

      <AboutAreaSection />

      <MissionAreaSecton />

      {/* <AboutSpecialOffer /> */}

      {/* <TestimonialSlider /> */}

      <VisionAreaSection />
    </>
  );
};

export default About;
