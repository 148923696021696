import React from "react";

const VisionAreaSection = () => {
  return (
    <>
      <section className="space bg-light">
        <div
          className="container"
          style={{
            backgroundImage: "url(assets/img/bg/map-bg.png)",
          }}
        >
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="title-area">
                <h2 className="sec-title h1">Our Vision</h2>
              </div>
            </div>
          </div>
          <div className="row mt-60 mb-70">
            <div className="col-lg-6 col-md-6 history-steped">
              <span className="divider"></span>
              <div className="vs-history">
                <div className="header-area">
                  <h3 className="history-title">Lucky Tour and Travel </h3>
                </div>
                <p className="history-text" style={{textAlign : "justify"}}>
                  Lucky Tour and Travel to be leading agency within the travel
                  trade providing economical and effective services to our
                  customers, To be leaders and trend setters within the travel
                  business, all told avenues of business that we are related to
                  and utilizing all our efforts and stylish services not solely
                  throughout our guests visit however additionally accomplish
                  long-run relationships forever to be recognized as a number
                  one contributor of quality travel and business connected
                  services worldwide.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 history-steped">
              <span className="divider"></span>
              <div className="vs-history">
                <div className="header-area">
                  <h3 className="history-title">Lucky Tour and Travel </h3>
                </div>
                <p className="history-text" style={{textAlign : "justify"}}>
                  We’re committed to high-level services so as to attain total
                  consumer satisfaction. Lucky Tour and Travel wish to satisfy
                  and exceed all business clients’ goals and objectives, attempt
                  for excellence in quality, integrity, and price altogether
                  that we have a tendency to do.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionAreaSection;
