import React from "react";
import { Link, NavLink } from "react-router-dom";

const MobileMenu = ({ isOpen, toggleMobileMenu }) => {
  return (
    <div className={`vs-menu-wrapper ${isOpen ? "vs-body-visible" : ""}`}>
      <div className="vs-menu-area text-center">
        <button className="vs-menu-toggle" onClick={toggleMobileMenu}>
          <i className="fal fa-times"></i>
        </button>
        <div className="mobile-logo">
          <Link to="/">
            <img src="assets/img/white logo.png" alt="Travolo" />
          </Link>
        </div>
        <div className="vs-mobile-menu">
          <ul>
            <li>
              <NavLink
                exact="true"
                to="/"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                Home
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                About Us
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/packages"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                Packages
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                Gallery
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? "link-dom active" : ""
                }
                onClick={toggleMobileMenu}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
