import React from "react";
import { Link } from "react-router-dom";

const SideMenu = ({ isOpen, toggleSideMenu }) => {
  return (
    <div
      className={`sidemenu-wrapper offcanvas-wrapper ${
        isOpen ? "d-block" : "d-none"
      } d-lg-block`}
    >
      <div className="sidemenu-content">
        <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
          <i className="far fa-times"></i>
        </button>
        <div className="widget">
          <div className="vs-widget-about">
            <div className="footer-logo">
              <Link to="index.html">
                <img src="assets/img/logo.svg" alt="Travolo" />
              </Link>
            </div>
            <p className="footer-text">
              Ut tellus dolor, dapibus eget, elementum ifend cursus eleifend,
              elit. Aenea ifen dn tor wisi Aliquam er at volutpat. Dui ac tui
              end cursus eleifendrpis.
            </p>
            <div className="info-social style2">
              <Link to="#">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-google"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="widget">
          <h3 className="widget_title">Recent Articles</h3>
          <div className="recent-post-wrap">
            <div className="recent-post">
              <div className="media-img">
                <Link to="blog-details.html">
                  <img src="assets/img/blog/recent-post1.jpg" alt="Blog" />
                </Link>
              </div>
              <div className="media-body">
                <div className="recent-post-meta">
                  <i className="fal fa-calendar-alt"></i>
                  <Link to="blog.html">December 15, 2022</Link>
                </div>
                <h4 className="post-title">
                  <Link className="text-inherit" to="blog-details.html">
                    We are Giving Amazing Tour for VIP
                  </Link>
                </h4>
              </div>
            </div>
            <div className="recent-post">
              <div className="media-img">
                <Link to="blog-details.html">
                  <img src="assets/img/blog/recent-post2.jpg" alt="Blog" />
                </Link>
              </div>
              <div className="media-body">
                <div className="recent-post-meta">
                  <i className="fal fa-calendar-alt"></i>
                  <Link to="blog.html">March 13, 2022</Link>
                </div>
                <h4 className="post-title">
                  <Link className="text-inherit" to="blog-details.html">
                    The journey journal chronicles of a wandering soul
                  </Link>
                </h4>
              </div>
            </div>
            <div className="recent-post">
              <div className="media-img">
                <Link to="blog-details.html">
                  <img src="assets/img/blog/recent-post3.jpg" alt="Blog" />
                </Link>
              </div>
              <div className="media-body">
                <div className="recent-post-meta">
                  <i className="fal fa-calendar-alt"></i>
                  <Link to="blog.html">August 23, 2022</Link>
                </div>
                <h4 className="post-title">
                  <Link className="text-inherit" to="blog-details.html">
                    Roam And Revel captivating at tellus.
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
