// import React from "react";

// const GalleryArea = () => {
//   return (
//     <section className="space space-bottom">
//       <div className="container">
//         <div className="row justify-content-between align-items-center">
//           <div className="col-lg-7 mx-auto">
//             <div className="title-area text-center">
//               <span className="sec-subtitle">Top Images</span>
//               <h2 className="sec-title h1">
//                 Explore the Beautiful Places Around the World
//               </h2>
//             </div>
//           </div>
//         </div>

//         <div className="destination-list mb-5">
//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-1.jpeg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-2.jpg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-3.jpeg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-4.png)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-5.png)",
//             }}
//           ></div>
//         </div>

//         <div className="destination-list">
//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-6.jpg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-1.jpeg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-2.jpg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-3.jpeg)",
//             }}
//           ></div>

//           <div
//             className="destination-style3"
//             style={{
//               backgroundImage:
//                 "url(assets/img/destinations/gallery-img-4.png)",
//             }}
//           ></div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default GalleryArea;

import React, { useState } from "react";

const GalleryArea = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const images = [
    "assets/img/destinations/gallery-img-11.jpg",
    "assets/img/destinations/gallery-img-22.jpg",
    "assets/img/destinations/gallery-img-33.jpg",
    "assets/img/destinations/gallery-img-44.jpg",
    "assets/img/destinations/gallery-img-55.jpg",
    "assets/img/destinations/gallery-img-66.jpg",
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage("");
  };

  return (
    <section className="space space-bottom">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-7 mx-auto">
            <div className="title-area text-center">
              <span className="sec-subtitle">Top Images</span>
              <h2 className="sec-title h1">
                Explore the Beautiful Places Around the World
              </h2>
            </div>
          </div>
        </div>

        <div className="destination-list mb-5">
          {images.slice(0, 5).map((image, index) => (
            <div
              key={index}
              className="destination-style3"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => handleImageClick(image)}
            ></div>
          ))}
        </div>

        <div className="destination-list">
          {images.slice(0, 5).map((image, index) => (
            <div
              key={index}
              className="destination-style3"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => handleImageClick(image)}
            ></div>
          ))}
        </div>
      </div>

      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedImage} alt="Full size" />
          </div>
        </div>
      )}
    </section>
  );
};

export default GalleryArea;
