import React from "react";
import HeroArea from "./HeroArea";
import ServiceArea from "./ServiceArea";

const Services = () => {
  return (
    <>
      <HeroArea />

      <ServiceArea />
    </>
  );
};

export default Services;
