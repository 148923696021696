import React from "react";
// import TourSlider from "./TourPackagesSection";
import HeroArea from "./HeroArea";
import PackagesArea from "./PackagesArea";

const Packages = () => {
  return (
    <>
      <HeroArea />

      <PackagesArea />

      {/* <TourSlider /> */}
    </>
  );
};

export default Packages;
