import React from "react";

const ContactBox = () => {
  return (
    <>
      <section className="space contact-box_wrapper">
        <div className="outer-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="contact-box">
                  <div className="contact-box_icon">
                    <i className="fas fa-map-marked-alt"></i>
                  </div>
                  <h3 className="contact-box__title h5">Thailand Address</h3>
                  <p className="contact-box__text">Md Jewel Rana</p>
                  <p className="contact-box__text">Managing Director</p>
                  <p className="contact-box__text">+66886204280</p>
                  <p className="contact-box__text">+66840860743</p>
                  <p className="contact-box__text">
                    21/30 M5 Nongprue Banglamung Chonburi 20150
                  </p>
                  <p className="contact-box__text">Thailand</p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="contact-box">
                  <div className="contact-box_icon">
                    <i className="fas fa-map-marked-alt"></i>
                  </div>
                  <h3 className="contact-box__title h5">Bangladesh Address</h3>
                  <p className="contact-box__text">Md Jewel Rana</p>
                  <p className="contact-box__text">Managing Director</p>
                  <p className="contact-box__text">+8801735004027</p>
                  <p className="contact-box__text">
                    luckytourstravelsthailand@gmail.com
                  </p>
                  <p className="contact-box__text">
                    9 70/D Anowara Kunjo (2nd floor) Purana Polton Lane, V.I.P
                    Road KaKrail, Dhaka-1000
                  </p>
                  {/* <p className="contact-box__text">V.I.P Road KaKrail,</p>
                  <p className="contact-box__text">Dhaka-1000</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBox;
