import React from "react";
import { Link } from "react-router-dom";

const ServiceArea = () => {
  return (
    <section className="space-top space-extra-bottom position-relative">
      <div
        className="shape-mockup d-none d-xl-block z-index-negative"
        data-bottom="-10%"
        data-left="0%"
        data-right="0%"
      >
        <img
          className="w-100"
          src="assets/img/shape/service-shape-1.png"
          alt="svg"
        />
      </div>

      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="title-area">
              <h5 className="sec-title h5">
                Our top priority is customer satisfaction, and we are committed
                to delivering the highest quality services. Below are the
                services we offer to our valued customers.
              </h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/hotel.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Hotel Booking</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/flight-ticket.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Air Ticket</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/virtual-tour.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Tour Packages</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/transportation.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Transportation</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/medical.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Medical Appointment</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/currency.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Money Exchange</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/tourist.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">Tourist SIM Card</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 col-sm-6 col-12">
            <div className="features-style3">
              <div
                className="features-bg"
                style={{
                  backgroundImage: "url(assets/img/shape/features2.png)",
                }}
              ></div>
              <div className="features-image">
                <img src="assets/img/flaticon/b2b.png" alt="" />
              </div>
              <div className="features-content">
                <h3 className="features-title">B2B (Thailand)</h3>
                <p className="features-text">
                  Curabitur aliquet qugbfam isfbd dgui posuedfdre bladscfndit
                  vivgbagmus Bitur aliquet
                </p>
                <Link className="features-link" to="/contact">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1C12 0.447716 11.5523 5.87248e-07 11 1.00872e-06L2 4.60808e-07C1.44771 7.97982e-07 0.999999 0.447716 0.999999 1C1 1.55229 1.44771 2 2 2L10 2L10 10C10 10.5523 10.4477 11 11 11C11.5523 11 12 10.5523 12 10L12 1ZM1.70711 11.7071L11.7071 1.70711L10.2929 0.292894L0.292893 10.2929L1.70711 11.7071Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceArea;
