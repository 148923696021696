import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="footer-wrapper footer-layout1"
      style={{
        backgroundImage: "url(assets/img/bg/footer-bg.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="footer-top">
        <div className="shadow-color"></div>
        <div className="container">
          <div className="cta-style1">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-lg-6">
                <div className="cta-content">
                  <h2 className="cta-title">Ready to get started?</h2>
                  <p className="cta-text">
                    It only takes a few minutes to share your thinking with us.
                  </p>
                  <Link to="/contact" className="vs-btn style2">
                    Let's talk
                  </Link>
                </div>
              </div>
              <div className="col-md-5 col-sm-6">
                <div className="cta-image d-lg-block d-none">
                  <img src="assets/img/newsletter.png" alt="CTA " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="widget-area">
        <div className="container">
          <div className="row g-5 justify-content-between">
            <div className="col-md-6 col-xl-3">
              <div className="widget footer-widget">
                <div className="vs-widget-about">
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src="assets/img/white logo.png"
                        alt="Travolo"
                        className="logo"
                      />
                    </Link>
                  </div>
                  <p className="footer-text">
                    We Believe That Coming up with the Small Print of Your Trip
                    May be as Gratifying Because of the Trip Itself.
                  </p>
                  <div className="social-style1">
                    <Link to="#" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="#" target="_blank">
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link to="#" target="_blank">
                      <i className="fab fa-pinterest-p"></i>
                    </Link>
                    <Link to="#" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="widget footer-widget">
                <h4 className="widget_title">Our Instagram</h4>
                <div className="sidebar-gallery">
                  <Link
                    to="assets/img/footer/insta1.jpg"
                    className="popup-image"
                  >
                    <img
                      src="assets/img/footer/insta1.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                  <Link
                    to="assets/img/footer/insta2.jpg"
                    className="popup-image"
                  >
                    <img
                      src="assets/img/footer/insta2.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                  <Link
                    to="assets/img/footer/insta3.jpg"
                    className="popup-image"
                  >
                    <img
                      src="assets/img/footer/insta3.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                  <Link to="assets/img/footer/insta4.jpg" className="popup-">
                    <img
                      src="assets/img/footer/insta4.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                  <Link
                    to="assets/img/footer/insta5.jpg"
                    className="popup-image"
                  >
                    <img
                      src="assets/img/footer/insta5.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                  <Link
                    to="assets/img/footer/insta6.jpg"
                    className="popup-image"
                  >
                    <img
                      src="assets/img/footer/insta6.jpg"
                      alt="Gallery "
                      className="w-100"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-2">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Thailand Office</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li className="text-white">Md Jewel Rana</li>
                    <li className="text-white">Managing Director</li>
                    <li className="text-white">+66886204280</li>
                    <li className="text-white">+66840860743</li>
                    <li className="text-white">
                      21/30 M5 Nongprue Banglamung Chonburi 20150 Thailand
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Bangladesh Office</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                  <li className="text-white">Md Jewel Rana</li>
                  <li className="text-white">Managing Director</li>
                    <li className="text-white">+8801735004027</li>
                    <li className="text-white">
                      9 70/D Anowara Kunjo (2nd floor) Purana Polton Lane, V.I.P
                      Road KaKrail, Dhaka-1000
                    </li>
                    <li className="text-white">
                      luckytourstravelsthailand@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright-wrap">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-auto">
              <p className="copyright-text">
                Copyright <i className="fal fa-copyright"></i>
                {new Date().getFullYear()}{" "}
                <Link to="/">Lucky Tour & Travels</Link>. Developed By{" "}
                <Link to="https://ekattorit.com">
                  <span style={{ color: "red" }}>EKATTOR</span>{" "}
                  <span style={{ color: "green" }}>iT</span>
                </Link>
              </p>
            </div>
            <div className="col-auto d-none d-lg-block">
              <div className="copyright-menu">
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">Privacy</Link>
                  </li>
                  <li>
                    <Link to="#">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
