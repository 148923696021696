import React from "react";
import { Link } from "react-router-dom";

const AboutHeroSection = () => {
  return (
    <>
      <div
        className="breadcumb-wrapper"
        style={{
          backgroundImage: "url(assets/img/breadcumb/breadcumb-bg.jpg)",
          marginTop: "165px",
        }}
      >
        <div className="container z-index-common">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">About Us</h1>
            <div className="breadcumb-menu-wrap">
              <ul className="breadcumb-menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHeroSection;
