import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const slides = [
  {
    subtitle: "Let's Go Now",
    title: "Explore World And Find The Beauty",
    text: "Our top priority is customer satisfaction, and we are committed to delivering the highest quality services. Below are the services we offer to our valued customers.",
    img1: "assets/img/banner/lucky-tour-bannar1.jpg",
    img2: "assets/img/banner/lucky-tour-bannar2.jpg",
    link: "/about",
  },
  {
    subtitle: "Let's Go Now",
    title: "Kickstart Your Make-Believe Quest",
    text: "Our top priority is customer satisfaction, and we are committed to delivering the highest quality services. Below are the services we offer to our valued customers.",
    img1: "assets/img/banner/banner-img3.jpg",
    img2: "assets/img/banner/banner-img4.jpg",
    link: "/about",
  },
  {
    subtitle: "Let's Go Now",
    title: "Uncovering Stories, One Trip At A Time",
    text: "Our top priority is customer satisfaction, and we are committed to delivering the highest quality services. Below are the services we offer to our valued customers.",
    img1: "assets/img/banner/banner-img5.jpg",
    img2: "assets/img/banner/banner-img6.jpg",
    link: "/about",
  },
];

const HeroSection = () => {
  const sliderRef = useRef(null); // Ref to hold the Slider component instance
  const [activeSlide, setActiveSlide] = useState(0); // State to track active slide index

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next), // Update active slide index
  };

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index); // Programmatic navigation to slide index
  };

  return (
    <section
      className="hero-layout"
      style={{
        backgroundImage: "url(assets/img/banner/banner-bg-1.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "165px",
      }}
    >
      <div className="hero-mask">
        <div className="vs-carousel" id="hero-slider">
          <Slider {...settings} ref={sliderRef}>
            {slides.map((slide, index) => (
              <div className="hero-slide" key={index}>
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6">
                      <div className="hero-content">
                        <span className="hero-subtitle">{slide.subtitle}</span>
                        <h1 className="hero-title">{slide.title}</h1>
                        <p className="hero-text">{slide.text}</p>
                        <Link to={slide.link} className="vs-btn style4">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="hero-img">
                        <img className="img1" src={slide.img1} alt="hero" />
                        <img className="img2" src={slide.img2} alt="hero" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div
            className="slide-count vs-slider-tab"
            data-asnavfor="#hero-slider"
          >
            {slides.map((slide, index) => (
              <button
                key={index}
                className={`tab-btn ${activeSlide === index ? "active" : ""}`}
                onClick={() => goToSlide(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>

          {/* <div className="hero-bottom">
            <div className="container">
              <form className="hero-form">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <i className="fas fa-compass"></i>
                      <input type="text" placeholder="Where To?" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <i className="fas fa-calendar-alt"></i>
                      <select className="form-select" name="name">
                        <option defaultValue="" disabled="disabled" hidden="">
                          Select Month
                        </option>
                        <option value="">January</option>
                        <option value="">February</option>
                        <option value="">March</option>
                        <option value="">April</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <i className="fas fa-thumbtack"></i>
                      <select className="form-select" name="name">
                        <option defaultValue="" disabled="disabled" hidden="">
                          Select Type
                        </option>
                        <option value="">Adventure</option>
                        <option value="">Combining</option>
                        <option value="">Sporting</option>
                        <option value="">Domestic</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <button className="vs-btn style4">Find Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
