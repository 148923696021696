import React from "react";
import { Link } from "react-router-dom";

const PackagesArea = () => {
  return (
    <section className="space-top space-extra-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div
              className="destination-style1"
              // style={{ border: "2px solid #ccc" }}
            >
              <Link to="/packages">
                <img
                  src="assets/img/destinations/top-package-img11.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">7500฿</span>
              {/* <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Thailand</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div> */}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div
              className="destination-style1"
              // style={{ border: "2px solid #ccc" }}
            >
              <Link to="/packages">
                <img
                  src="assets/img/destinations/top-package-img22.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">7000฿</span>
              {/* <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Thailand</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div> */}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div
              className="destination-style1"
              // style={{ border: "2px solid #ccc" }}
            >
              <Link to="/packages">
                <img
                  src="assets/img/destinations/top-package-img33.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">5700฿</span>
              {/* <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Thailand</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div> */}
            </div>
          </div>

          {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-4.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$399</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Italy</Link>
                </h4>
                <p className="destination-text">Explore View & Get Relax</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-5.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$299</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Netherlands</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-6.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$399</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">India</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div>
            </div>
          </div> */}

          {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-7.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$299</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Canada</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-8.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$399</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Mexico</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="destination-style1">
              <Link to="/packages">
                <img
                  src="assets/img/destinations/destinations-1-9.jpg"
                  alt="destination "
                />
              </Link>
              <span className="destination-price">$399</span>
              <div className="destination-info">
                <h4 className="destination-name">
                  <Link to="#">Indonesia</Link>
                </h4>
                <p className="destination-text">Explore Sea & Get Relax</p>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="vs-pagination pt-lg-2">
          <ul>
            <li>
              <Link to="">
                <i className="fas fa-chevron-left"></i>
              </Link>
            </li>
            <li>
              <Link to="">1</Link>
            </li>
            <li>
              <Link to="">2</Link>
            </li>
            <li>
              <Link to="">3</Link>
            </li>
            <li>
              <Link to="">
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
};

export default PackagesArea;
