import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = ({
  toggleSidemenu,
  toggleMobileMenu,
  toggleSearchBox,
  toggleCart,
}) => {
  return (
    <header
      className="vs-header header-layout1"
      style={{ position: "fixed", width: "100%", top: "0", right: "0" }}
    >
      <div className="container py-2">
        <div className="header-top">
          <div className="row justify-content-between align-items-center">
            <div className="col d-none d-lg-block">
              <ul className="header-contact">
                <li>
                  <i className="fas fa-envelope"></i>
                  <Link to="mailto:luckytourstravelsthailand@gmail.com">
                    luckytourstravelsthailand@gmail.com
                  </Link>
                </li>
                <li>
                  <i className="fas fa-phone-alt"></i>
                  <Link to="tel:+66886204280">+66886204280</Link>
                </li>
              </ul>
            </div>

            <div className="col-auto">
              <div className="header-social">
                <Link to="">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="">
                  <i className="fab fa-instagram"></i>
                </Link>
                <Link to="">
                  <i className="fab fa-pinterest-p"></i>
                </Link>
                <Link to="">
                  <i className="fab fa-twitter"></i>
                </Link>
              </div>
            </div>

            {/* <div className="col-auto d-flex">
              <Link className="user-btn" to="sign-up.html">
                <i className="far fa-user-circle"></i>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <div className="sticky-wrapper">
        {/* <div className="sticky-active"> */}
        <div>
          <div className="container position-relative z-index-common">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="vs-logo">
                  <Link to="/">
                    <img src="assets/img/logo.png" alt="logo" />
                  </Link>
                </div>
              </div>

              <div className="col text-end">
                <nav className="main-menu menu-style1 d-none d-lg-block">
                  <ul>
                    <li>
                      <NavLink
                        exact="true"
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        Home
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/about"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        About Us
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/services"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        Services
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/packages"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        Packages
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/gallery"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        Gallery
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                          isActive ? "link-dom active" : ""
                        }
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </nav>

                <button
                  className="vs-menu-toggle d-inline-block d-lg-none"
                  onClick={toggleMobileMenu}
                >
                  <i className="fal fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
