import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";

const testimonials = [
  {
    quoteImg: "assets/img/shape/quote1.png",
    rating: 3,
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation nim vengiam, quis ullamco.",
    avatar: "assets/img/testimonial/jowel-rana.jpeg",
    name: "Md Jewel Rana",
    designation: "Managing Director",
  },
  // {
  //   quoteImg: "assets/img/shape/quote1.png",
  //   rating: 4,
  //   text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation nim vengiam, quis ullamco.",
  //   avatar: "assets/img/testimonial/jowel-rana.jpeg",
  //   name: "Md Jewel Rana",
  //   designation: "Managing Director",
  // },
  // {
  //   quoteImg: "assets/img/shape/quote1.png",
  //   rating: 3,
  //   text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation nim vengiam, quis ullamco.",
  //   avatar: "assets/img/testimonial/jowel-rana.jpeg",
  //   name: "Md Jewel Rana",
  //   designation: "Managing Director",
  // },
  // {
  //   quoteImg: "assets/img/shape/quote1.png",
  //   rating: 5,
  //   text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation nim vengiam, quis ullamco.",
  //   avatar: "assets/img/testimonial/jowel-rana.jpeg",
  //   name: "Md Jewel Rana",
  //   designation: "Managing Director",
  // },
];

const TestimonialSlider = () => {
  const mainSlider = useRef(null);
  const navSlider = useRef(null);

  const settingsMain = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    asNavFor: navSlider.current,
  };

  // const settingsNav = {
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   asNavFor: mainSlider.current,
  //   focusOnSelect: true,
  //   arrows: false,
  //   dots: false,
  // };

  return (
    <section
      className="space testimonial-style1 home_testimonial"
      style={{
        backgroundImage: "url(assets/img/bg/testimonial-bg.jpg)",
        marginBottom: "150px",
      }}
    >
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-xxl-5 col-xl-5">
            <div className="title-area">
              <span className="sec-subtitle">Lucky Tour and Travel</span>
              <h2 className="sec-title h1">Our Managing Director’s Words</h2>
              {/* <p className="sec-text">
                Curabitur aliquet quam id dui posuere blandit. Vivamus magna
                justo, lacinia eget consectetur sed, convgallis at tellus.
              </p>
              <Link to="/contact" className="vs-btn style3">
                View More
              </Link> */}
            </div>
          </div>
          <div className="col-xxl-6 col-xl-7">
            <div className="testi-style1">
              {/* <Slider
                {...settingsNav}
                className="testi-avaters vs-slider-tab"
                ref={navSlider}
              >
                {testimonials.map((testimonial, index) => (
                  <div key={index}>
                    <button
                      className={`tab-btn ${index === 0 ? "active" : ""}`}
                    >
                      <img src={testimonial.avatar} alt="avatar" />
                    </button>
                  </div>
                ))}
              </Slider> */}
              <div className="testi-shape"></div>
              <Slider
                {...settingsMain}
                className="vs-carousel"
                id="testId"
                ref={mainSlider}
              >
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="position-relative z-index-common">
                    <div className="testi-quote">
                      <img src={testimonial.quoteImg} alt="quote" />
                    </div>

                    {/* <div className="testi-rating">
                      {[...Array(5)].map((star, i) => (
                        <i
                          key={i}
                          className={
                            i < testimonial.rating
                              ? "fas fa-star"
                              : "far fa-star"
                          }
                        ></i>
                      ))}
                    </div> */}

                    <p className="testi-text">{testimonial.text}</p>
                    <div className="testi-avater d-flex justify-content-center align-items-center">
                      <img src={testimonial.avatar} alt="customer" />
                    </div>
                    <h3 className="testi-name">{testimonial.name}</h3>
                    <span className="testi-degi">
                      {testimonial.designation}
                    </span>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
