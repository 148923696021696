/* eslint-disable jsx-a11y/anchor-is-valid */

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import MobileMenu from "./components/MobileMenu";
import PopupSearchBox from "./components/PopupSearchBox";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideMenu from "./components/SideMenu";
import CartSidebar from "./components/CartSidebar";
import { useState } from "react";
import Packages from "./pages/Packages/Packages";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Top from "./components/Top";
import Gallery from "./pages/Gallery/Gallery";
import Error from "./Error";
// import Blogs from "./pages/Blogs/Blogs";
// import BlogDetails from "./pages/Blogs/BlogDetails";

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const toggleCartSidebar = () => {
    setIsCartSidebarOpen(!isCartSidebarOpen);
  };

  const toggleSearchBox = () => {
    setIsSearchBoxOpen(!isSearchBoxOpen);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Top />
        <>
          <MobileMenu
            isOpen={isMobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />

          <PopupSearchBox
            isOpen={isSearchBoxOpen}
            toggleSearchBox={toggleSearchBox}
          />

          <Header
            toggleMobileMenu={toggleMobileMenu}
            toggleSideMenu={toggleSideMenu}
            toggleCartSidebar={toggleCartSidebar}
            toggleSearchBox={toggleSearchBox}
          />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/gallery" element={<Gallery />} />
            {/* <Route path="/blogs" element={<Blogs />} /> */}
            {/* <Route path="/blogs-details" element={<BlogDetails />} /> */}
            <Route path="/contact" element={<Contact />} />

            <Route path="*" element={<Error />} />
          </Routes>

          <Footer />

          <SideMenu isOpen={isSideMenuOpen} toggleSideMenu={toggleSideMenu} />

          <CartSidebar
            isOpen={isCartSidebarOpen}
            toggleCartSidebar={toggleCartSidebar}
          />

          <a href="#" className="scrollToTop scroll-btn">
            <i className="far fa-arrow-up"></i>
          </a>
        </>
      </BrowserRouter>
    </div>
  );
}

export default App;
