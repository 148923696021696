import React from "react";

const ContactForm = () => {
  return (
    <>
      <div className="space bg-light">
        <div className="container">
          <form
            className="ajax-contact"
            action="https://html.vecurosoft.com/mail.php"
            method="POST"
          >
            <div className="row justify-content-center text-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="title-area">
                  <span className="sec-subtitle">Contact Us</span>
                  <h2 className="sec-title h1">Get In Touch</h2>
                  <p className="sec-text">
                    We Believe That Coming up with the Small Print of Your Trip
                    May be as Gratifying Because of the Trip Itself.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  placeholder="Enter Your First Name"
                  name="fname"
                  id="fname"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  placeholder="Enter Your Last Name"
                  name="lname"
                  id="lname"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  id="email"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 form-group">
                <input
                  type="number"
                  placeholder="Phone No"
                  name="number"
                  id="number"
                  className="form-control"
                />
              </div>

              <div className="form-group col-12">
                <textarea
                  placeholder="Write Your Comment"
                  name="message"
                  id="message"
                  className="form-control"
                ></textarea>
              </div>
              <div className="col-md-auto pt-lg-3">
                <button className="vs-btn style4" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
