import React from "react";
import { Link } from "react-router-dom";

const GallerySection = () => {
  return (
    <>
      <section className="space space-extra-bottom gallery-style1">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="title-area mb-4">
                {/* <span className="sec-subtitle">Go & Discover</span> */}
                <h2 className="sec-title h1 text-start">
                  Lucky Tour and Travel
                </h2>
                <p className="sec-text">
                  Lucky Tour and Travel provides Tour package, visiting the city
                  is all regarding the fun of coming up with the main points of
                  your trip. Lucky Tour and Travel tends to believe that your
                  own personal trip needs you to own your own tour plan.
                </p>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 ">
                  <img
                    style={{ border: "2px solid #ccc" }}
                    className="gallery-image"
                    src="assets/img/gallery/lucky-tr-gallery-2.png"
                    alt="images"
                  />
                </div>

                <div className="col-xl-6 col-sm-6 ">
                  <img
                    style={{ border: "2px solid #ccc" }}
                    className="gallery-image"
                    src="assets/img/gallery/lucky-tr-gallery-3.png"
                    alt="images"
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="gallery-video">
                <img
                  style={{ border: "2px solid #ccc" }}
                  src="assets/img/gallery/lucky-tr-gallery-1.jpg"
                  alt="gallery-image"
                />
                <div className="gallery-btn">
                  <span>Watch Video</span>
                  <Link
                    to="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                    className="play-btn popup-video"
                  >
                    <i className="fas fa-play"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GallerySection;
