import React from "react";
import DestinationSlider from "./DestinationSlider";
// import BlogSection from "./BlogSection";
// import AboutBlog from "./AboutBlog";
import HeroSection from "./HeroSection";
// import TourPackagesSection from "./TourPackagesSection";
import GallerySection from "./GallerySection";
import FeaturesSection from "./FeaturesSection";
// import SpecialOffer from "./SpecialOffer";
import TestimonialSection from "./TestimonialSection";
import MissionAreaSecton from "../About/MissionAreaSecton";
import VisionAreaSection from "../About/VisionAreaSection";

const Home = () => {
  return (
    <>
      <HeroSection />

      {/* <AboutBlog /> */}

      <GallerySection />

      {/* <TourPackagesSection /> */}

      <DestinationSlider />

      <FeaturesSection />

      {/* from about  */}
      <MissionAreaSecton />
      <VisionAreaSection />
      {/* from about  */}

      {/* <SpecialOffer /> */}

      {/* <DestinationSlider /> */}

      <TestimonialSection />

      {/* <BlogSection /> */}
    </>
  );
};

export default Home;
