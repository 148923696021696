import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const DestinationSlider = () => {
  const sliderRef = useRef(null);

  const destinations = [
    {
      id: 1,
      imageUrl: "assets/img/destinations/top-package-img11.jpg",
      price: "7500฿",
      name: "Thailand",
      description: "Explore Sea & Get Relax",
      link: "/packages",
    },
    {
      id: 2,
      imageUrl: "assets/img/destinations/top-package-img22.jpg",
      price: "7000฿",
      name: "Thailand",
      description: "Explore Sea & Get Relax",
      link: "/packages",
    },
    {
      id: 3,
      imageUrl: "assets/img/destinations/top-package-img33.jpg",
      price: "5700฿",
      name: "Thailand",
      description: "Explore Sea & Get Relax",
      link: "/packages",
    },
    // {
    //   id: 4,
    //   imageUrl: "assets/img/destinations/destinations-1-8.jpg",
    //   price: "$158",
    //   name: "Mexico",
    //   description: "Explore World & Get Relax",
    //   link: "/packages",
    // },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <section
      className="space space-extra-bottom bg-light shape-mockup-wrap"
      style={{ backgroundImage: "url(assets/img/shape/Bg.png)" }}
    >
      <div
        className="shape-mockup d-none d-xl-block spin z-index-negative"
        style={{ top: "-20%", right: "-8%" }}
      >
        <img src="assets/img/shape/circle1.png" alt="circle" />
      </div>
      <div
        className="shape-mockup d-none d-xl-block z-index-negative"
        style={{ bottom: "13%", left: "0%" }}
      >
        <img src="assets/img/shape/walk.png" alt="circle" />
      </div>

      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-5 col-md-8">
            <div className="title-area">
              <span className="sec-subtitle">Top Packages</span>
              <h2 className="sec-title h1">Unforgettable Cities</h2>
              <p className="sec-text" style={{textAlign : "justify"}}>
                Lucky Tour and Travel provides Tour package, visiting the city
                is all regarding the fun of coming up with the main points of
                your trip. Lucky Tour and Travel tends to believe that your own
                personal trip needs you to own your own tour plan.
              </p>
            </div>
          </div>
          <div className="col-auto">
            <div className="sec-btns">
              <button className="icon-btn" onClick={handlePrev}>
                <i className="fas fa-chevron-left"></i>
              </button>
              <button className="icon-btn" onClick={handleNext}>
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>

        <Slider
          {...sliderSettings}
          className="destinationSlide custom-slider"
          ref={sliderRef}
        >
          {destinations.map((destination) => (
            <div key={destination.id}>
              <div className="destination-style1">
                <Link to={destination.link}>
                  <img src={destination.imageUrl} alt="destination" />
                </Link>
                <span className="destination-price">{destination.price}</span>
                {/* <div className="destination-info">
                  <h4 className="destination-name">
                    <Link to={destination.link}>{destination.name}</Link>
                  </h4>
                  <p className="destination-text">{destination.description}</p>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default DestinationSlider;
