import React from "react";
import HeroArea from "./HeroArea";
import ContactBox from "./ContactBox";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <>
      <HeroArea />

      <ContactBox />

      <ContactForm />
    </>
  );
};

export default Contact;
