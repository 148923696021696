import React from "react";
import HeroArea from "./HeroArea";
import GalleryArea from "./GalleryArea";

const Gallery = () => {
  return (
    <>
      <HeroArea />

      <GalleryArea />
    </>
  );
};

export default Gallery;
