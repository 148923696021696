import React from "react";

const PopupSearchBox = ({ isOpen, toggleSearchBox }) => {
  return (
    <div
      className={`popup-search-box ${isOpen ? "d-block" : "d-none"} d-lg-block`}
    >
      <button className="searchClose" onClick={toggleSearchBox}>
        <i className="fal fa-times"></i>
      </button>
      <form action="#">
        <input
          type="text"
          className="border-theme"
          placeholder="What are you looking for"
        />
        <button type="submit">
          <i className="fal fa-search"></i>
        </button>
      </form>
    </div>
  );
};

export default PopupSearchBox;
