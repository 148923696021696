import React from "react";

const MissionAreaSecton = () => {
  return (
    <>
      <section className="space bg-light">
        <div
          className="container"
          style={{
            backgroundImage: "url(assets/img/bg/map-bg.png)",
          }}
        >
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="title-area">
                <h2 className="sec-title h1">Our Mission</h2>
              </div>
            </div>
          </div>
          <div className="row mt-60 mb-70">
            <div className="col-lg-6 col-md-6 history-steped">
              <span className="divider"></span>
              <div className="vs-history">
                <div className="header-area">
                  <h3 className="history-title">Lucky Tour and Travel </h3>
                  {/* <span className="history-date">2023</span> */}
                </div>
                <p className="history-text" style={{textAlign : "justify"}}>
                  Lucky Tour and Travel an area destination management company
                  for Thailand. We Lucky Tour and Travel provide comprehensive
                  tour programs to company and individual travelers with sort of
                  custom-designed holidays and business travel with an excellent
                  level of attention. We have a tendency to concentrate on
                  handling tours incoming to Thailand. All our tour itineraries
                  are tailor created to suit the wants of our customers.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 history-steped">
              <span className="divider"></span>
              <div className="vs-history">
                <div className="header-area">
                  <h3 className="history-title">Lucky Tour and Travel </h3>
                  {/* <span className="history-date">2022</span> */}
                </div>
                <p className="history-text" style={{textAlign : "justify"}}>
                  Lucky Tour and Travel quality policy can guarantee tighter
                  management over the standard of travel and connected service
                  and our dedicated company travel team keeps a close
                  relationship with our purchasers to produce them with an
                  immediate response. With Lucky Tour and Travel, each travel
                  chance is going to be an instant to care for and really be an
                  ‘Inspiration for pleasure’ and that we can live up to our
                  slogan of “Making Holidays Unforgettable”
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MissionAreaSecton;
