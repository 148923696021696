import React from "react";
import { Link } from "react-router-dom";

const CartSidebar = ({ isOpen, toggleCartSidebar }) => {
  return (
    <div
      className={`sideCart-wrapper offcanvas-wrapper ${
        isOpen ? "d-block" : "d-none"
      } d-lg-block`}
    >
      <div className="sidemenu-content">
        <button
          className="closeButton border-theme bg-theme-hover sideMenuCls"
          onClick={toggleCartSidebar}
        >
          <i className="far fa-times"></i>
        </button>
        <div className="widget widget_shopping_cart">
          <h3 className="widget_title">Shopping cart</h3>
          <div className="widget_shopping_cart_content">
            <ul className="cart_list">
              <li className="mini_cart_item">
                <Link to="shop.html" className="remove">
                  <i className="fal fa-trash-alt"></i>
                </Link>
                <Link to="shop.html">
                  <img src="assets/img/products/p-1-1.png" alt="Cart" />
                  Modern Cow Boy Hat
                </Link>
                <span className="quantity">
                  1 ×{" "}
                  <span className="amount">
                    <span>$</span>100.00
                  </span>
                </span>
              </li>
              <li className="mini_cart_item">
                <Link to="shop.html" className="remove">
                  <i className="fal fa-trash-alt"></i>
                </Link>
                <Link to="shop.html">
                  <img src="assets/img/products/p-1-2.png" alt="Cart" />
                  Cat Eye Sunglasses
                </Link>
                <span className="quantity">
                  1 ×{" "}
                  <span className="amount">
                    <span>$</span>10.00
                  </span>
                </span>
              </li>
            </ul>
            <div className="total">
              <strong>Subtotal:</strong>
              <span className="amount">
                <span>$</span>110.00
              </span>
            </div>
            <div className="buttons">
              <Link to="cart.html" className="vs-btn style4">
                View cart
              </Link>
              <Link to="checkout.html" className="vs-btn style4">
                Checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSidebar;
